import React from 'react';
import DashboardContainer from './dashboard/dashboard-container';

class DashBoard extends React.Component {
  render() {
    return (
      <div>
        <DashboardContainer
          handleOrgAssetsSave={this.props.handleOrgAssetsSave}
          selectedIdToContainer={[this.props.selected, this.props.submitted]}
        />
      </div>
    );
  }
}

export default DashBoard;
