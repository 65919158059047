import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import logoutApp from './../helper/logout-app.js';
import DateTime from './dashboard/date-time.js';
import { Dropdown, Icon, Button } from 'semantic-ui-react';
import AssetSuggest from './dashboard/asset-suggestion.js';

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      visible: false,
      open: false,
    };
  }

  logout = () => {
    logoutApp();
    this.props.user.changeToken('', '', false);
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const isPaymentPage = window.location.pathname.includes(
      '/onlinepayment/success',
    );

    const isReportPage = window.location.pathname.includes('/report');

    let { isLoggedIn } = this.state;

    if (window.localStorage.getItem('user')) {
      isLoggedIn = true;
    }

    const path = this.props.location.pathname;

    return (
      <div className='ui fixed borderless main menu custom-menu navbar-color'>
        {window.innerWidth > 768 && (
          <Button
            style={{
              marginRight: '5px',
              color: '#fff',
              background: '#001529',
              padding: '0',
            }}
          >
            <Link
              to='/'
              style={{
                color: '#fff',
                background: '#001529',
                padding: '3px',
                textDecoration: 'none',
              }}
            >
              <img
                src='/images/Logo-1.png'
                alt='Finder logo'
                style={{
                  height: '43px',
                  width: 'auto',
                  marginLeft: '55px',
                  padding: '0 0 3px 0',
                }}
              />
            </Link>
          </Button>
        )}

        {window.innerWidth > 768 && path === '/' ? <DateTime /> : null}
        {window.innerWidth <= 768 && path === '/' ? (
          <Button
            className='responsive-date-menu'
            icon='clock'
            onClick={this.toggleVisibility}
            style={{
              position: 'fixed',
              top: 14,
              left: 55,
              color: '#fff',
              background: '#001529',
              zIndex: 1000,
              fontSize: '24px',
              padding: '0',
              margin: '0',
            }}
          />
        ) : null}
        {isLoggedIn ? (
          <div>
            {this.state.visible ? (
              <div
                style={{
                  width: '260px',
                  color: '#001529',
                  height: '140px',
                  top: '38px',
                  position: 'absolute',
                  backgroundColor: '#001529',
                  marginLeft: '50px',
                  padding: '0 5px',
                  borderBottomLeftRadius: '4px',
                  borderBottomRightRadius: '4px',
                  boxShadow:
                    '0 3px 5px -1px rgba(0,0,0,.2), 0 5px 8px 0 rgba(0,0,0,.14), 0 1px 14px 0 rgba(0,0,0,.12)',
                }}
              >
                <DateTime visible={this.state.visible} />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className='right menu'>
          {false ? (
            <Button
              style={{ background: '#424242', color: '#01D47F' }}
              onClick={this.onOpenModal}
            >
              Video
            </Button>
          ) : null}

          <div className='ui item asset-options'>
            {isLoggedIn && !isPaymentPage && !isReportPage ? (
              <div>
                <AssetSuggest
                  orgAssets={this.props.orgAssets}
                  handleSelected={this.props.handleSelected}
                />
              </div>
            ) : null}
          </div>
          {window.localStorage.getItem('login_type') !== 'secret_key' && (
            <div className='ui item font-color'>
              {isLoggedIn ? (
                <Dropdown
                  icon={
                    <Icon
                      name='user'
                      style={{ color: 'white', fontSize: '20px' }}
                    />
                  }
                >
                  <Dropdown.Menu>
                    {window.localStorage.getItem('username') ? null : (
                      <Dropdown.Item>
                        <Link to='/profile'>
                          <Icon name='user outline' />
                          Profile
                        </Link>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={this.logout}>
                      <Icon name='sign out' />
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Link to='/login'>Login</Link>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(NavigationBar);
