import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

class PaymentSuccess extends React.Component {
  render() {
    return (
      <div>
        <section className='payment-container'>
          <div className='payment-header'>
            <img
              alt='Payment success logo'
              src='/images/payment/checked.png'
              className='payment-header-icon'
            />
            Payment Successful!
          </div>
          <div className='payment-subheader'>
            Thank you! Your payment has been received.
          </div>
          <br />
          <Button size='large'>
            <Link to='/'>OK</Link>
          </Button>
        </section>
      </div>
    );
  }
}

export default PaymentSuccess;
