import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { getData } from './../../helper/dataService.js';
import { observer, inject } from 'mobx-react';
import { REGULAR_PATH } from '../../../env';

@inject('assets')
@observer
class StaffPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      org: [],
      userOrg: '',
      userMobile: '',
      userEmail: '',
      user: [],
      allUsers: [],
      assets: [],
      selectedOrganization: '',
      selectedUser: '',
      userSearchDirect: '',
      directAssetsDropdown: [],
      directAssets: [],
      selectedDirectAsset: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.staffVisible !== prevProps.staffVisible) {
      this.setState({
        selectedOrganization: '',
      });
    }
  }

  // ORGANIZATION
  onSearchChangeOrganization = (e) => {
    if (e.target.value.length >= 4) {
      var searchOrgResponse = getData(
        `${REGULAR_PATH}/staff/organizations?search=${e.target.value}&access_token=`,
      );
      searchOrgResponse.then((res) => {
        let result = [];
        res.data.forEach((item) => {
          result.push({
            key: item._id.$oid,
            text: item.name,
            value: item._id.$oid,
          });
        });
        this.setState({
          org: result,
        });
      });
    }
  };

  handleChangeOrganization = (e, { value }) => {
    var orgResponse = getData(
      `${REGULAR_PATH}/staff/org_members?org_oid=${value}&access_token=`,
    );
    orgResponse.then((res) => {
      let temp = [];
      res.data.forEach((user) => {
        temp.push({
          key: user._id.$oid,
          text: `${user.email} ${user.mobile ? `[${user.mobile}]` : ''}`,
          value: user._id.$oid,
        });
      });

      this.setState({
        user: temp,
        selectedOrganization: value,
      });
    });
  };

  // USER
  directUserSearch = (e) => {
    if (e.target.value.length >= 4) {
      let users = getData(
        `${REGULAR_PATH}/staff/users?search=${e.target.value}&access_token=`,
      );
      users.then((res) => {
        let temp = [];
        res.data.forEach((user) => {
          temp.push({
            key: user._id.$oid,
            text: `${user.email} ${user.mobile ? `[${user.mobile}]` : ''}`,
            value: user._id.$oid,
          });
        });
        this.setState({
          user: temp,
          userOrg: '',
          userMobile: '',
          selectedOrganization: '',
        });
      });
    }
  };

  handleChangeUser = (e, { value }) => {
    var assetResponse = getData(
      `${REGULAR_PATH}/staff/org_member_assets?user_oid=${value}&access_token=`,
    );

    let assets = [];
    assetResponse
      .then((response) => {
        response.data.assets.forEach((asset) => {
          var temp = {};
          temp['key'] = asset._id.$oid;
          temp['value'] = asset._id.$oid;
          temp['label'] = asset.name;
          temp['active'] = asset.allow_view;
          assets.push(temp);
        });
        this.setState({
          userMobile: response.data.mobile,
          userEmail: response.data.email,
        });
        if (response.data.organization) {
          this.setState({
            userOrg: response.data.organization.name,
          });
          window.localStorage.setItem(
            'organization',
            response.data.organization._id.$oid,
          );
        } else {
          this.setState({
            userOrg: '',
          });
          window.localStorage.setItem('organization', '');
        }
        return assets;
      })
      .then((val) => {
        this.setState({
          assets: val.sort(function (x, y) {
            return x.active === y.active ? 0 : x.active ? -1 : 1;
          }),
          selectedUser: value,
        });
        this.props.handleAssetsSave([this.state.assets]);
        window.localStorage.setItem(
          'current_user',
          JSON.stringify([
            this.state.userOrg,
            this.state.userMobile,
            this.state.userEmail,
          ]),
        );
        setTimeout(() => {
          this.setState({
            selectedOrganization: '',
            selectedUser: '',
          });
        }, 500);
      });
  };

  // ASSET
  directAssetSearch = (e) => {
    if (e.target.value.length >= 4) {
      var directAssetResponse = getData(
        `${REGULAR_PATH}/staff/assets?search=${e.target.value}&access_token=`,
      );

      directAssetResponse.then((res) => {
        let temp = [];
        let assets = [];
        res.data.forEach((asset) => {
          temp.push({ key: asset.name, text: asset.name, value: asset.name });
          var item = {};
          item['key'] = asset._id.$oid;
          item['value'] = asset._id.$oid;
          item['label'] = asset.name;
          item['active'] = asset.allow_view;
          assets.push(item);
        });
        this.setState({
          directAssetsDropdown: temp,
          assets: assets,
        });
      });
    }
  };

  directAssetSearchList = (e, { value }) => {
    let selected = this.state.assets.filter((a, b) => {
      return a.label === value;
    });
    this.props.handleAssetsSave([selected], ['', '']);
    this.setState({
      selectedDirectAsset: '',
    });
    window.localStorage.setItem('current_user', JSON.stringify([]));
  };

  render() {
    const {
      selectedOrganization,
      selectedUser,
      selectedDirectAsset,
      directAssetsDropdown,
    } = this.state;

    return (
      <div>
        <div className='ui center aligned grid'>
          <div className='row' style={{ height: 80 }}>
            <i className='circular user icon' style={{ fontSize: 35 }}></i>
          </div>
          <div className='row'>
            <h3>{window.localStorage.getItem('staffName')}</h3>
          </div>
        </div>
        <div style={{ textAlign: 'left', padding: 10 }}>
          <h4>Search Organization</h4>
          <Dropdown
            options={this.state.org}
            placeholder='Type and Select Organization'
            search
            selection
            fluid
            selectOnBlur={false}
            value={selectedOrganization}
            onSearchChange={this.onSearchChangeOrganization}
            onChange={this.handleChangeOrganization}
          />
          <br />

          <h4>Search User</h4>
          <Dropdown
            options={this.state.user}
            placeholder='Type and Select User'
            search
            selection
            fluid
            selectOnBlur={false}
            value={selectedUser}
            onChange={this.handleChangeUser}
            onSearchChange={this.directUserSearch}
          />
          <br />

          <h4>Search Asset</h4>
          <Dropdown
            options={directAssetsDropdown}
            placeholder='Type and Select Asset'
            search
            selection
            fluid
            selectOnBlur={false}
            value={selectedDirectAsset}
            onChange={this.directAssetSearchList}
            onSearchChange={this.directAssetSearch}
          />
        </div>
      </div>
    );
  }
}

export default StaffPanel;
