import moment from 'moment';

export const getIdsAndTime = (state) => {
  const momentToUnix = (time) => moment(time).unix();

  const { selectedAssets: ids, startTime, endTime } = state;
  const startTimeUnix = momentToUnix(startTime);
  const endTimeUnix = momentToUnix(endTime);
  return { ids, startTime: startTimeUnix, endTime: endTimeUnix };
};

export const getActiveAssetList = (assets) => {
  return assets
    .filter((item) => item.active)
    .map((item, index) => ({
      key: item.key,
      value: item.value,
      text: `[${index + 1}] ${item.label}`,
      name: item.label,
    }));
};

export const getDuration = (compareTo, compareWith) => {
  return parseInt(compareTo.diff(compareWith, 'days')) + 1;
};

export const getOrg = () => window.localStorage.getItem('organization');
