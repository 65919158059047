import React from 'react';
import { Dropdown, Input, Header, Checkbox } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getActiveAssetList } from './helper/helper';
import { inject, observer } from 'mobx-react';

@inject('assets')
@observer
class TimeAndAsset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetsList: [],
      selectedAssets: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      from: '',
      to: '',
    };
  }

  componentDidMount() {
    if (window.localStorage.getItem('reports_state')) {
      const {
        selectedAssets = [],
        startTime,
        endTime,
      } = JSON.parse(window.localStorage.getItem('reports_state'));
      this.setState({
        selectedAssets,
        startTime: moment(startTime),
        endTime: moment(endTime),
      });
    }
    this.setState({
      assetsList: getActiveAssetList(this.props.assets.data),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedAssets, startTime, endTime, from, to } = this.state;
    if (prevState.selectedAssets !== selectedAssets) {
      if (selectedAssets.length >= 1) {
        this.props.showOtherMenu(true);
      } else if (selectedAssets.length === 0) {
        this.props.showOtherMenu(false);
      }
    }
    if (prevState.from !== from || prevState.to !== to) {
      this.assetsRange();
    }
    if (
      prevState.selectedAssets !== selectedAssets ||
      prevState.startTime !== startTime ||
      prevState.endTime !== endTime
    ) {
      window.localStorage.setItem(
        'reports_state',
        JSON.stringify({
          selectedAssets,
          startTime,
          endTime,
        }),
      );
    }
  }

  handleStartTime = (value) => {
    this.setState({
      startTime: value,
    });
  };

  handleEndTime = (value) => {
    this.setState({
      endTime: value,
    });
  };

  handleAssetSelect = (e, data) => {
    this.setState({ selectedAssets: data.value });
  };

  assetsRange = () => {
    const { from, to } = this.state;
    if (from <= to) {
      this.setState((prevState) => ({
        selectedAssets: prevState.assetsList
          .map((item) => item.value)
          .slice(from - 1, to),
      }));
    } else {
      this.setState({ selectedAssets: [] });
    }
  };

  handleFrom = (e, data) => {
    const value = parseInt(data.value);
    this.setState({
      from: value && value > 0 ? value : '',
    });
  };

  handleTo = (e, data) => {
    const value = parseInt(data.value);
    this.setState({
      to: value && value > 0 ? value : '',
    });
  };

  handleAllAssetSelect = () => {
    this.setState((prevState) => ({
      selectedAssets:
        prevState.selectedAssets.length === prevState.assetsList.length
          ? []
          : this.state.assetsList.map((item) => item.value),
    }));
  };

  render() {
    const { assetsList, selectedAssets, startTime, endTime, from, to } =
      this.state;

    return (
      <div className=' center-of-page'>
        <div className='date-time-report'>
          <DatePicker
            selected={startTime}
            onChange={this.handleStartTime}
            showTimeSelect
            maxDate={moment().add(0, 'days')}
            timeIntervals={5}
            dateFormat='LLL'
          />
          <span
            style={{
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            —
          </span>
          <DatePicker
            selected={endTime}
            onChange={this.handleEndTime}
            showTimeSelect
            maxDate={moment().add(0, 'days')}
            timeIntervals={5}
            dateFormat='LLL'
          />
        </div>
        <br />
        <div>
          <Dropdown
            placeholder='Select Asset(s)'
            fluid
            multiple
            search
            selection
            options={assetsList}
            onChange={this.handleAssetSelect}
            value={selectedAssets}
          />
        </div>
        <br />
        <Checkbox
          toggle
          label={`${
            assetsList.length === selectedAssets.length
              ? 'Remove All'
              : 'Select All'
          }`}
          onChange={this.handleAllAssetSelect}
        />
        <Header as='h3'>Select assets from range</Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Input
            label='From'
            name='from'
            type='number'
            onChange={this.handleFrom}
            value={from}
            placeholder='10'
          />
          <Input
            label='To'
            name='to'
            type='number'
            onChange={this.handleTo}
            value={to}
            placeholder='15'
          />
        </div>
      </div>
    );
  }
}

export default TimeAndAsset;
