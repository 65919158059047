import React from 'react';
import { Message, Icon } from 'semantic-ui-react';
import NavigationBar from './navigation-bar';
import { observer, inject } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { getData } from '../helper/dataService';
import { REGULAR_PATH } from '../../env';

@inject('assets', 'user')
@observer
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      orgAssets: [],
      submitted: false,
      isLoggedIn: false,
      assetLoaded: false,
      showGlobalMessage: false,
    };
  }

  componentDidMount() {
    const ignoreAssetsLoadPaths = ['/connect-asset'];
    const skipLoadingAssets = ignoreAssetsLoadPaths.some(
      (path) => path === this.props.location.pathname,
    );

    if (skipLoadingAssets) {
      this.setState({ assetLoaded: true });
      return;
    }

    getData(`${REGULAR_PATH}/assets/list?access_token=`)
      .then((res) => {
        const assets = res.data.map(({ _id, allow_view, name, asset_type }) => {
          const id = _id.$oid;

          return {
            key: id,
            value: id,
            active: allow_view,
            label: name,
            asset_type: asset_type,
          };
        });

        this.props.assets.handleSaveAssets(assets);
        this.setState({ assetLoaded: true });
      })
      .catch(() => this.setState({ assetLoaded: true }));

    window.localStorage.setItem(
      'organization',
      window.localStorage.getItem('real_org'),
    );
    window.localStorage.setItem('current_user', JSON.stringify([]));

    if (JSON.parse(window.localStorage.getItem('showGlobalMessage')) === null) {
      this.setState({ showGlobalMessage: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user !== nextProps.user.isLoggedIn) {
      this.setState({ isLoggedIn: nextProps.user.isLoggedIn });
    }
  }
  handleSelected = (selected, submitted) => {
    this.setState({
      selected: selected,
      submitted: submitted,
    });
  };

  handleOrgAssetsSave = (value) => {
    this.setState({
      orgAssets: value,
    });
  };

  handleHideGlobalMessage = () => {
    this.setState({ showGlobalMessage: false });
    window.localStorage.setItem('showGlobalMessage', false);
  };

  render() {
    const { assetLoaded, selected, submitted, orgAssets, showGlobalMessage } =
      this.state;

    const pathName = this.props.location.pathname;

    return assetLoaded ? (
      <div className='container'>
        {pathName !== '/connect-asset' ? (
          <NavigationBar
            orgAssets={orgAssets}
            handleSelected={this.handleSelected}
            user={this.props.user}
          />
        ) : null}
        <div style={{ marginTop: '4.5rem' }} id='mine'>
          {React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, {
              selected,
              submitted,
              handleOrgAssetsSave: this.handleOrgAssetsSave,
            });
          })}
        </div>
        <ToastContainer
          style={{ bottom: '4rem' }}
          autoClose={3000}
          position='bottom-center'
        />
        {showGlobalMessage ? (
          <Message
            style={{
              width: '100%',
              position: 'fixed',
              bottom: '0',
              left: '0',
              textAlign: 'center',
              borderRadius: 0,
            }}
            color='black'
          >
            <div>
              By using our site, you acknowledge that you have read and
              understand our{' '}
              <a
                href='https://finder-lbs.com/terms-and-conditions/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a
                href='https://finder-lbs.com/privacy-policy/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy Policy
              </a>
              .
              <Icon
                name='close'
                onClick={this.handleHideGlobalMessage}
                size='large'
                style={{ cursor: 'pointer', marginLeft: '20px' }}
              />
            </div>
          </Message>
        ) : null}
      </div>
    ) : (
      <div className='full-screen' id='spinner'>
        <div className='sk-cube-grid'>
          <div className='sk-cube sk-cube1'></div>
          <div className='sk-cube sk-cube2'></div>
          <div className='sk-cube sk-cube3'></div>
          <div className='sk-cube sk-cube4'></div>
          <div className='sk-cube sk-cube5'></div>
          <div className='sk-cube sk-cube6'></div>
          <div className='sk-cube sk-cube7'></div>
          <div className='sk-cube sk-cube8'></div>
          <div className='sk-cube sk-cube9'></div>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
