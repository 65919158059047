import React from 'react';
import { Segment, Dropdown, Button, Input, Message } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getActiveAssetList } from './helper/helper';
import { reportRequest } from './helper/dataService';
import { inject, observer } from 'mobx-react';

const reports = [
  {
    key: 'custom_distance_in_day',
    value: 'Custom Distance in Day',
    text: 'Custom Distance in Day',
  },
  // {
  //   key: 'halt_report',
  //   value: 'Halt Report',
  //   text: 'Halt Report'
  // },
];

@inject('assets')
@observer
class EmailReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      report: '',
      interval: 5,
      email: '',
      sending: false,
      messageType: '',
      message: '',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        assetsList: getActiveAssetList(this.props.assets.data),
        email: localStorage.getItem('email'),
      });
    }, 1000);
  }

  handleStartTime = (value) => {
    this.setState({
      startTime: value,
    });
  };

  handleEndTime = (value) => {
    this.setState({
      endTime: value,
    });
  };

  handleReportSelect = (e, data) => {
    this.setState({ report: data.value });
  };

  handleInterval = (e) => {
    const value = e.target.value;
    if (value >= 0 && value <= 180) {
      this.setState({ interval: value });
    }
  };

  handleSendRequest = () => {
    this.setState({ sending: true });
    let url = '';
    const { report, startTime, endTime, interval, email } = this.state;

    if (report === 'Halt Report') {
      url = 'requests/report/ignition_halt_excel';
    } else if (report === 'Custom Distance in Day') {
      url = 'requests/report/custom_distance_day_wise_excel';
    }

    reportRequest(url, {
      user_id: localStorage.getItem('user_id'),
      org_id: localStorage.getItem('organization'),
      target_email: email,
      from: moment(startTime).unix(),
      to: moment(endTime).unix(),
      interval: interval ? Number(interval * 60) : 0,
    })
      .then(({ data: { status } }) => {
        if (status === 'ok') {
          this.setState({
            messageType: 'success',
            message: `Request received successfully. You will get e-mail within 24 hours in ${email}`,
          });
          setTimeout(() => this.setState({ message: '' }), 10000);
        } else {
          this.setState({
            messageType: 'failed',
            message: 'All fields are required.',
          });
        }
        this.setState({ sending: false });
      })
      .catch(() =>
        this.setState({
          messageType: 'failed',
          message: 'Sorry! Something went wrong.',
          sending: false,
        }),
      );
  };

  render() {
    const {
      startTime,
      endTime,
      report,
      interval,
      email,
      sending,
      messageType,
      message,
    } = this.state;

    const days = parseInt(endTime.diff(startTime, 'days')) + 1;
    let maxDate = 31;
    if (report === 'Halt Report') {
      maxDate = 7;
    }

    if (
      !['54f2cb4b421aa9493557fc09'].includes(
        window.localStorage.getItem('organization'),
      )
    ) {
      return <h3>You are not eligible.</h3>;
    }

    return (
      <Segment>
        <div className='center-of-page'>
          <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>
            Request Report Download
          </h3>
          <label>Date Range [Max {maxDate} days] </label>
          <div className='date-time-report'>
            <DatePicker
              selected={startTime}
              onChange={this.handleStartTime}
              showTimeSelect
              maxDate={moment().add(0, 'days')}
              timeIntervals={5}
              dateFormat='LLL'
            />
            <span
              style={{
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              —
            </span>
            <DatePicker
              selected={endTime}
              onChange={this.handleEndTime}
              showTimeSelect
              maxDate={moment().add(0, 'days')}
              timeIntervals={5}
              dateFormat='LLL'
            />
          </div>
          <br />
          <label>Report</label>
          <Dropdown
            fluid
            selection
            options={reports}
            onChange={this.handleReportSelect}
            value={report}
          />

          {report === 'Halt Report' && (
            <div>
              <br />
              <label>Interval [0 - 180 Minutes]</label>
              <Input
                fluid
                type='number'
                min='0'
                max='180'
                onChange={this.handleInterval}
                value={interval}
              />
            </div>
          )}

          <br />
          {email && report && days <= maxDate ? (
            <Button
              color='teal'
              content='Send Request'
              onClick={this.handleSendRequest}
              disabled={sending}
              loading={sending}
            />
          ) : null}
          {days > maxDate && (
            <Message color='red'>
              You can't download this report more than {maxDate} day range.
            </Message>
          )}

          <br />
          {message && (
            <Message color={messageType === 'failed' ? 'red' : 'green'}>
              {message}
            </Message>
          )}
        </div>
      </Segment>
    );
  }
}

export default EmailReports;
