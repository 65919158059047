import { action, observable } from 'mobx';

class Assets {
  @observable
  data = [];

  @action
  handleSaveAssets = (assets) => {
    this.data = assets;
  };
}

export default new Assets();
