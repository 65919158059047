import React from 'react';
import {
  Form,
  Input,
  Button,
  Message,
  Dropdown,
  Loader,
} from 'semantic-ui-react';
import axios from 'axios';
import { REGULAR_PATH } from '../../../env';
import { inject, observer } from 'mobx-react';

const token = () => window.localStorage.getItem('user');

@inject('assets')
@observer
class Add extends React.Component {
  state = {
    point: '',
    amount: 0,
    points: [],
    pointsLoading: true,
    submit: false,
    status: '',
    message: '',
    assetList: [],
    asset: '',
  };

  componentDidMount() {
    axios
      .get(`${REGULAR_PATH}/dpoints?access_token=${token()}`)
      .then(({ data }) => {
        this.setState({ points: data, pointsLoading: false });
      })
      .catch(() => {
        this.setState({ points: [], pointsLoading: false });
      });

    const assetList = this.props.assets.data;
    this.setState({
      assetList: assetList
        .filter((item) => item.active)
        .map((item) => ({
          key: item.key,
          value: item.value,
          text: item.label,
        })),
    });
  }

  saveData = () => {
    const { point, amount, asset } = this.state;

    this.setState({ submit: true, status: '' });

    const data = {
      point_name: point,
      point_type: this.state.points.find(({ name }) => name === point).type,
      amount: amount,
      asset_name: asset,
    };

    axios
      .post(
        `${REGULAR_PATH}/oilinfo?access_token=${token()}`,
        JSON.stringify(data),
      )
      .then((response) => {
        const { status, message } = response.data;

        this.setState({ status: status, message: message, submit: false });

        if (status === 'success') {
          this.setState({
            point: '',
            amount: '',
            asset: '',
          });
        }
      })
      .catch(() => {
        this.setState({ submit: false });
      });
  };

  handleInput = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };

  render() {
    const {
      point,
      amount,
      points,
      pointsLoading,
      submit,
      status,
      message,
      assetList,
      asset,
    } = this.state;

    if (pointsLoading) {
      return (
        <Loader active inline='centered'>
          Loading...
        </Loader>
      );
    }

    return (
      <div style={{ maxWidth: '600px', margin: '10px 10%' }}>
        <Form>
          <Form.Field
            control={Dropdown}
            fluid
            selection
            search
            options={assetList.map(({ key, text, value }) => ({
              key,
              value: text,
              text,
              name: key,
              id: key,
            }))}
            label='Asset'
            placeholder='Select Asset'
            value={asset}
            onChange={this.handleInput}
            name='asset'
          />
        </Form>
        <br />
        <Form>
          <Form.Field
            control={Dropdown}
            fluid
            selection
            search
            options={points.map(({ name, type, _id }) => ({
              key: name,
              value: name,
              text: `${name} [${type}]`,
              name: name,
              id: _id.$oid,
            }))}
            label='Points'
            placeholder='Select Point'
            value={point}
            onChange={this.handleInput}
            name='point'
          />
        </Form>

        <br />
        <Form>
          <Form.Field
            control={Input}
            label='Amount'
            onChange={this.handleInput}
            value={amount}
            name='amount'
          />
        </Form>

        <Button
          primary
          style={{ marginTop: '15px' }}
          onClick={this.saveData}
          loading={submit}
          disabled={submit || !point || !amount || !asset}
        >
          Submit
        </Button>

        {status === 'success' && (
          <Message positive>Saved successfully.</Message>
        )}

        {status && status !== 'success' && (
          <Message negative> {message}</Message>
        )}
      </div>
    );
  }
}

export default Add;
