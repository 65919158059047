import React from 'react';
import { Button } from 'semantic-ui-react';
import { REGULAR_PATH } from '../../../env';
import { Link } from 'react-router-dom';

const token = () => window.localStorage.getItem('user');

class MonthlyBillPdf extends React.Component {
  state = {
    pdf_url: '',
  };

  componentDidMount() {
    const { billId = '' } = this.props.match.params;
    const pdf_url = `${REGULAR_PATH}/monthly_bill_pdf?bill_id=${billId}&access_token=${token()}`;
    this.setState({ pdf_url });
    window.open(pdf_url);
    window.location.href = '/monthlybill';
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <a
          style={{ display: 'inline', marginTop: '20px' }}
          href={this.state.pdf_url}
        >
          <Button size='big' color='blue'>
            Download
          </Button>
        </a>
        <br />
        <Link
          to='/monthlybill'
          style={{
            display: 'inline-block',
            marginTop: '25px',
            fontSize: '18px',
          }}
        >
          Redirecting to Monthly Bill
        </Link>
      </div>
    );
  }
}

export default MonthlyBillPdf;
