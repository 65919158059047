import React from 'react';
import ClickOutside from '../../helper/click-outside';
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Input,
  Checkbox,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

@inject('assets')
@observer
class AssetSuggestion extends React.Component {
  constructor(props) {
    super(props);
    this.selectTimeOutId = null;
    this.state = {
      options: [],
      selectAll: false,
      selected: [],
      search: '',
      openDropdown: false,
    };
  }

  componentDidMount() {
    this.generateOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.orgAssets !== prevProps.orgAssets) {
      const assets = this.props.orgAssets;
      const options = assets.map(({ active, label, value }) => ({
        id: value,
        name: label,
        active,
        checked: false,
      }));

      this.setState({
        selected: [],
        selectAll: false,
        search: '',
        openDropdown: false,
        options,
      });

      this.props.assets.handleSaveAssets(assets);
      this.props.handleSelected([], true);
      this.props.history.push('/');
    }
  }

  generateOptions = () => {
    const options = this.props.assets.data
      .map(({ key, active, label }) => ({
        id: key,
        name: label,
        active,
        checked: false,
      }))
      .sort(function (x, y) {
        return x.active === y.active ? 0 : x.active ? -1 : 1;
      });

    this.setState({ options });
  };

  handleSearch = (_, data) => {
    this.setState({ search: data.value });
  };

  handleSelect = (_, data) => {
    const options = this.state.options.map((option) => ({
      ...option,
      checked: option.id === data.id ? data.checked : option.checked,
    }));

    const selected = options
      .filter((option) => option.checked)
      .map((option) => option.id);

    this.setState({ options, selected, selectAll: false });

    if (this.selectTimeOutId) {
      clearTimeout(this.selectTimeOutId);
    }

    this.selectTimeOutId = setTimeout(() => {
      this.props.handleSelected(selected, true);
      this.props.history.push('/');
    }, 1000);
  };

  handleSelectAll = (checked) => {
    const selected = [];
    const options = this.state.options.map((option) => {
      if (checked && option.active) {
        selected.push(option.id);
      }
      return {
        ...option,
        checked: option.active ? checked : option.checked,
      };
    });

    this.setState({
      options,
      selected,
      selectAll: checked,
    });

    this.props.handleSelected(selected, true);
    this.props.history.push('/');
  };

  openDropdown = () => {
    this.setState({ openDropdown: true });
  };

  closeDropdown = () => {
    this.setState({ openDropdown: false });
  };

  toggleDropdown = () =>
    this.setState({ openDropdown: !this.state.openDropdown });

  render() {
    const { options, search, selected, selectAll, openDropdown } = this.state;

    const totalSelected = selected.length;
    const filteredOptions = options.filter(({ name }) =>
      name.toLowerCase().includes(search.toLowerCase()),
    );

    if (options.length > 0) {
      return (
        <ClickOutside handleEvent={this.closeDropdown}>
          <div>
            <Dropdown
              floating
              trigger={
                <span
                  onClick={this.toggleDropdown}
                  style={{ padding: '0 5px' }}
                >
                  {totalSelected > 0
                    ? `${totalSelected} selected`
                    : 'SELECT ASSET'}
                </span>
              }
              open={openDropdown}
              style={{ color: 'white' }}
            >
              <DropdownMenu>
                <Input
                  placeholder='Search For Your Asset'
                  className='search'
                  onChange={this.handleSearch}
                />
                <DropdownMenu scrolling>
                  {filteredOptions.length >= 500 || search.length > 0 ? null : (
                    <DropdownItem
                      value='Select All'
                      content={
                        <Checkbox
                          label={'Select All'}
                          checked={selectAll}
                          onClick={(_, data) =>
                            this.handleSelectAll(data.checked)
                          }
                        />
                      }
                    />
                  )}

                  {filteredOptions.map(({ id, name, active, checked }) => (
                    <DropdownItem
                      key={id}
                      value={id}
                      content={
                        <Checkbox
                          id={id}
                          label={name}
                          disabled={!active}
                          checked={checked}
                          onClick={this.handleSelect}
                        />
                      }
                    />
                  ))}
                </DropdownMenu>
              </DropdownMenu>
            </Dropdown>
          </div>
        </ClickOutside>
      );
    } else {
      return <span />;
    }
  }
}

export default withRouter(AssetSuggestion);
