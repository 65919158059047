import React from 'react';
import { Button, Divider, Icon, Modal, Grid, Segment } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import { alertMessage } from '../../../helper/alert-message.js';
import { getData } from '../../../helper/dataService.js';
import Spinner from '../../UI/Spinner/Spinner';
import FuelGauge from '../../gauge/Gauge';
import { Link } from 'react-router-dom';
import { REGULAR_PATH } from '../../../../env';
import TPMS from '../../tpms/TPMS';
import IOElements from './IOElements';
import { getDuration } from '../../reports/helper/helper.js';
import moment from 'moment';
import EngineCommand from './EngineCommand';
import Direction from './Direction';
import { isCrown } from '../../../helper/check-org.js';
import JC400Stream from './stream/jc400/jc400-stream.js';
import JC120Stream from './stream/jc120-stream.js';
import JC181Stream from './stream/jc181/jc181-stream.js';
import TemperatureGauge from './TemperatureGauge.js';
import { digitalInputValueFormat } from '../../../helper/digital-input-value-format.js';

function generateRoute(id, start, end) {
  if (id !== undefined) {
    const assetId = id.assetId;
    const url = `${REGULAR_PATH}/assets/${assetId}/history_data_fuel?from=${moment(
      start,
    ).unix()}&to=${moment(end).unix()}&access_token=`;
    return getData(url);
  }
}

@inject('timer')
@observer
class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'DEFAULT',
      speed: 'No Limit',
      status: false,
      time: new Date().toString(),
      asset_type: '',
      isFetchingAnim: false,
      isFetchingRoute: false,
      engineStatus: false,
      fuel: 0,
      fuel_connected: false,
      tank_size: 0,
      fuel_level: 0,
      position: [0, 0],
      max_voltage: 0,
      min_voltage: 0,
      reverse_voltage: false,
      acc_connected: false,
      engine_sensor: false,
      valid_till: Date.now(),
      device_type: null,
      showTpmsData: false,
      showTemperatureGauge: false,
      tpms_connected: false,
      tpms_data: [],
      liveModeClicked: false,
      io_element_status: false,
      assetId: '',
      engine_control: false,
      engine_locked: false,
      crownData: {},
      video: false,
      temperatures: {
        sensor_1: {
          enabled: false,
          value: 0,
        },
        sensor_2: {
          enabled: false,
          value: 0,
        },
        sensor_3: {
          enabled: false,
          value: 0,
        },
        sensor_4: {
          enabled: false,
          value: 0,
        },
        sensor_5: {
          enabled: false,
          value: 0,
        },
        sensor_6: {
          enabled: false,
          value: 0,
        },
        sensor_7: {
          enabled: false,
          value: 0,
        },
        sensor_8: {
          enabled: false,
          value: 0,
        },
      },
      temperature_sensor_name: '',
      temperature_sensor_2_name: '',
      temperature_sensor_3_name: '',
      temperature_sensor_4_name: '',
      temperature_sensor_5_name: '',
      temperature_sensor_6_name: '',
      temperature_sensor_7_name: '',
      temperature_sensor_8_name: '',
      digital_input_1: false,
      digital_input_2: false,
      digital_input_3: false,
      digital_input_sensor_1: false,
      digital_input_sensor_2: false,
      digital_input_sensor_3: false,
      digital_input_sensor_1_reversed: false,
      digital_input_sensor_2_reversed: false,
      digital_input_sensor_3_reversed: false,
      humidity_1: 0,
      humidity_2: 0,
      humidity_3: 0,
      humidity_4: 0,
      humidity_sensor_1: false,
      humidity_sensor_2: false,
      humidity_sensor_3: false,
      humidity_sensor_4: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({ name: 'DEFAULT' });
    }

    if (
      (prevProps.id === this.props.id || prevProps.id !== this.props.id) &&
      prevProps.data !== this.props.data &&
      this.props.id < this.props.data.length
    ) {
      this.setState({ liveModeClicked: false });
      const single = this.props.data[this.props.id];

      if (single.fuel_connected === 'True') {
        this.setState({
          fuel: single.fuel,
          fuel_connected: true,
          tank_size: single.tank_size,
        });
      } else {
        this.setState({
          fuel: 0,
          fuel_connected: false,
          tank_size: 0,
        });
      }

      this.setState({
        name: single.name || 'Not Available',
        speed: single.children[0].speed || 0,
        time: single.children[2].time || 'Not Available',
        position: single.position || [0.0, 0.0],
        status: single.children[4].active || 'Not Available',
        engineStatus: single.children[5].engine || false,
        asset_type: single.asset_type || 'Not Available',
        valid_till: single.valid_till || 'Not Available',
        device_type: single.device_type || 'Not Available',
        tpms_data: single.tpms_data || [],
        tpms_connected: single.tpms_connected || false,
        assetId: single.assetId || '',
        engine_control: single.engine_control,
        engine_locked: single.engine_locked,
        video: single.video,
      });

      if (single.io_element_status) {
        this.setState({ io_element_status: true });
      } else {
        this.setState({ io_element_status: false });
      }

      if (single.acc_connected === 'True') {
        this.setState({ acc_connected: true });
      } else {
        this.setState({ acc_connected: false });
      }

      if (single.engine_sensor === 'True') {
        this.setState({ engine_sensor: true });
      } else {
        this.setState({ engine_sensor: false });
      }

      if (isCrown() && single.crownData) {
        this.setState({ crownData: single.crownData });
      }

      this.setState({
        ...this.state.temperatures,
        temperatures: {
          sensor_1: {
            enabled: single.temperature_sensor_1,
            value: single.temperature_1 || 0,
          },
          sensor_2: {
            enabled: single.temperature_sensor_2,
            value: single.temperature_2 || 0,
          },
          sensor_3: {
            enabled: single.temperature_sensor_3,
            value: single.temperature_3 || 0,
          },
          sensor_4: {
            enabled: single.temperature_sensor_4,
            value: single.temperature_4 || 0,
          },
          sensor_5: {
            enabled: single.temperature_sensor_5,
            value: single.temperature_5 || 0,
          },
          sensor_6: {
            enabled: single.temperature_sensor_6,
            value: single.temperature_6 || 0,
          },
          sensor_7: {
            enabled: single.temperature_sensor_7,
            value: single.temperature_7 || 0,
          },
          sensor_8: {
            enabled: single.temperature_sensor_8,
            value: single.temperature_8 || 0,
          },
        },
      });

      this.setState({ digital_input_1: single.digital_input_1 });
      this.setState({ digital_input_2: single.digital_input_2 });
      this.setState({ digital_input_3: single.digital_input_3 });

      this.setState({ digital_input_sensor_1: single.digital_input_sensor_1 });
      this.setState({ digital_input_sensor_2: single.digital_input_sensor_2 });
      this.setState({ digital_input_sensor_3: single.digital_input_sensor_3 });

      this.setState({
        digital_input_sensor_1_reversed: single.digital_input_sensor_1_reversed,
      });
      this.setState({
        digital_input_sensor_2_reversed: single.digital_input_sensor_2_reversed,
      });
      this.setState({
        digital_input_sensor_3_reversed: single.digital_input_sensor_3_reversed,
      });

      this.setState({ humidity_1: single.humidity_1 });
      this.setState({ humidity_2: single.humidity_2 });
      this.setState({ humidity_3: single.humidity_3 });
      this.setState({ humidity_4: single.humidity_4 });

      this.setState({ humidity_sensor_1: single.humidity_sensor_1 });
      this.setState({ humidity_sensor_2: single.humidity_sensor_2 });
      this.setState({ humidity_sensor_3: single.humidity_sensor_3 });
      this.setState({ humidity_sensor_4: single.humidity_sensor_4 });

      this.setState({
        temperature_sensor_name: single.temperature_sensor_name,
      });
      this.setState({
        temperature_sensor_2_name: single.temperature_sensor_2_name,
      });
      this.setState({
        temperature_sensor_3_name: single.temperature_sensor_3_name,
      });
      this.setState({
        temperature_sensor_4_name: single.temperature_sensor_4_name,
      });
      this.setState({
        temperature_sensor_5_name: single.temperature_sensor_5_name,
      });
      this.setState({
        temperature_sensor_6_name: single.temperature_sensor_6_name,
      });
      this.setState({
        temperature_sensor_7_name: single.temperature_sensor_7_name,
      });
      this.setState({
        temperature_sensor_8_name: single.temperature_sensor_8_name,
      });

      this.setState({
        digital_input_sensor_1_name: single.digital_input_sensor_1_name,
      });
      this.setState({
        digital_input_sensor_2_name: single.digital_input_sensor_2_name,
      });
      this.setState({
        digital_input_sensor_3_name: single.digital_input_sensor_3_name,
      });
    }
  }

  formatData = (res) => {
    const temp = {};
    temp['children'] = [];
    const result = [];
    if (res.data.length > 1) {
      res.data.forEach((x, i) => {
        const obj = {
          key: i,
          position: [x.loc.coordinates[1], x.loc.coordinates[0]],
          children: [
            { asset_type: this.state.asset_type },
            { name: this.state.name },
            { acc_connected: this.state.acc_connected },
            { engine_sensor: this.state.engine_sensor },
            { speed: x.speed },
            { bearing: x.bearing },
            { time: x.time },
          ],
        };

        if (this.state.fuel_connected) {
          obj.fuel = x.current_fuel;
          obj.fuelConnected = true;
          obj.tankSize = this.state.tank_size;
        } else {
          obj.fuelConnected = false;
        }

        if (this.state.temperature_sensor) {
          obj.temperatureSensor = true;
          obj.temperature = Math.round(x.temperature);
        } else {
          obj.temperatureSensor = false;
        }
        result.push(obj);
      });
    } else if (res.data['status']) {
      alertMessage('You can not view this asset.For viewing more.');
    } else {
      alertMessage('Sorry, no data found.');
      return;
    }
    return result;
  };

  startAnimation = (res) => {
    if (res.data.length) {
      this.setState({ isFetchingAnim: false });
      const result = this.formatData(res);
      const routetest = false;
      this.props.passAnimateData([], routetest);
      this.props.passAnimateData(result, routetest);
    } else {
      this.setState({ isFetchingAnim: false });
      alertMessage('Sorry, no data found.');
    }
  };

  animate = () => {
    const start = this.props.timer.start;
    const end = this.props.timer.end;

    if (getDuration(end, start) > 1) {
      alertMessage("You can't select this report more than 1 day range");
    } else if (this.props.id < this.props.data.length) {
      localStorage.setItem('liveMode', false);

      const animate = generateRoute(this.props.data[this.props.id], start, end);

      this.setState({
        isFetchingAnim: true,
        liveModeClicked: false,
        landmarkClicked: false,
      });
      animate
        .then((res) => {
          this.startAnimation(res);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  route = () => {
    const start = this.props.timer.start;
    const end = this.props.timer.end;

    if (getDuration(end, start) > 1) {
      alertMessage("You can't select this report more than 1 day range");
    } else if (this.props.id < this.props.data.length) {
      localStorage.setItem('routeData', 'yes');
      localStorage.setItem('liveMode', false);

      this.setState({
        isFetchingRoute: true,
        liveModeClicked: false,
        landmarkClicked: false,
      });

      generateRoute(this.props.data[this.props.id], start, end)
        .then((res) => {
          this.setState({ isFetchingRoute: false });

          const result = this.formatData(res);
          // stop live reload when user click route button
          const routetest = false;
          this.props.passRouteData([], routetest);
          this.props.passRouteData(result, routetest);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  assetLiveMode = () => {
    this.setState({ liveModeClicked: true });
    localStorage.removeItem('routeData');
    localStorage.setItem('liveMode', true);
    this.props.makeLiveModeOn(true);
  };

  showLandMark = () => {
    if (this.props.id < this.props.data.length) {
      const status = !this.state.landmarkClicked;
      this.setState({ landmarkClicked: status });
      const id = this.props.data[this.props.id];
      this.props.showLandMark(status, id);
    }
  };

  changeDistance(distance) {
    if (this.props.id < this.props.data.length) {
      const current = {
        info: this.props.data[this.props.id],
        radius: distance,
      };

      const response = getData(
        `${REGULAR_PATH}/assets/${current.info.assetId}/nearest_assets?radius=${distance}&access_token=`,
      );
      response.then((res) => {
        this.props.passNearVehicle([res.data, current]);
      });
    }
  }

  handleTpmsData = () => {
    this.setState({ showTpmsData: !this.state.showTpmsData });
  };

  handleTemperatureGauge = () => {
    this.setState({ showTemperatureGauge: !this.state.showTemperatureGauge });
  };

  render() {
    const {
      name,
      speed,

      time,
      position,
      isFetchingAnim,
      isFetchingRoute,
      fuel,
      fuel_connected,
      tank_size,
      acc_connected,
      engine_sensor,
      valid_till,
      device_type,
      showTpmsData,
      tpms_connected,
      tpms_data,
      liveModeClicked,
      landmarkClicked,
      io_element_status,
      assetId,
      engine_control,
      engine_locked,
      crownData,
      video,
      showTemperatureGauge,
      temperatures,
      digital_input_1,
      digital_input_2,
      digital_input_3,
      digital_input_sensor_1,
      digital_input_sensor_2,
      digital_input_sensor_3,
      temperature_sensor_name,
      temperature_sensor_2_name,
      temperature_sensor_3_name,
      temperature_sensor_4_name,
      temperature_sensor_5_name,
      temperature_sensor_6_name,
      temperature_sensor_7_name,
      temperature_sensor_8_name,
      digital_input_sensor_1_name,
      digital_input_sensor_2_name,
      digital_input_sensor_3_name,
      humidity_1,
      humidity_2,
      humidity_3,
      humidity_4,
      humidity_sensor_1,
      humidity_sensor_2,
      humidity_sensor_3,
      humidity_sensor_4,
      digital_input_sensor_1_reversed,
      digital_input_sensor_2_reversed,
      digital_input_sensor_3_reversed,
    } = this.state;

    let temperature_sensor_count = 0;
    Object.values(temperatures).forEach((value) => {
      if (value.enabled) {
        temperature_sensor_count += 1;
      }
    });

    let temperature_chart_column = 0;
    if (temperature_sensor_count < 2) {
      temperature_chart_column = 1;
    } else if (temperature_sensor_count <= 4 && temperature_sensor_count >= 2) {
      temperature_chart_column = 2;
    } else {
      temperature_chart_column = 4;
    }

    const {
      sensor_1,
      sensor_2,
      sensor_3,
      sensor_4,
      sensor_5,
      sensor_6,
      sensor_7,
      sensor_8,
    } = temperatures;

    const isTemperatureSensorEnabled =
      sensor_1.enabled ||
      sensor_2.enabled ||
      sensor_3.enabled ||
      sensor_4.enabled ||
      sensor_5.enabled ||
      sensor_6.enabled ||
      sensor_7.enabled ||
      sensor_8.enabled;

    const isHumiditySensorEnabled =
      humidity_sensor_1 ||
      humidity_sensor_2 ||
      humidity_sensor_3 ||
      humidity_sensor_4;

    const {
      delivery_number = '',
      chalan_number = '',
      chalan_quantity = '',
      destination_address = '',
      driver_name = '',
      driver_mobile_number = '',
      trip_status = '',
      source_plant_name = '',
      vehicle_type = '',
    } = crownData;

    const current_user = JSON.parse(
      window.localStorage.getItem('current_user'),
    );

    if (this.state.name !== 'DEFAULT') {
      return (
        <div
          style={{
            textAlign: 'left',
            paddingTop: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                gap: 5,
                justifyContent: 'center',
                alignItems: 'center',
                padding: '12px 0px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <i className='circular car icon' />
              </div>
              <div>
                <h4>{name}</h4>
              </div>
            </div>

            {current_user.length > 0 ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <div>{current_user[0]}</div>
                  <div>{current_user[1]}</div>
                  <div>{current_user[2]}</div>
                </div>
              </div>
            ) : null}
          </div>

          <div>
            <div style={{ marginBottom: '10px' }}>
              <Button.Group size='small' primary>
                <Button
                  onClick={this.assetLiveMode}
                  disabled={liveModeClicked}
                  loading={liveModeClicked}
                >
                  Live Mode
                </Button>
                <Button onClick={this.showLandMark} toggle>
                  <div style={{ display: 'flex' }}>
                    <div>
                      {landmarkClicked ? (
                        <Icon name='circle' />
                      ) : (
                        <Icon name='circle outline' />
                      )}
                    </div>

                    <div>Landmark</div>
                  </div>
                </Button>
                <Button
                  disabled={isFetchingRoute}
                  loading={isFetchingRoute}
                  onClick={this.route}
                >
                  Route
                </Button>
                <Button
                  disabled={isFetchingAnim}
                  loading={isFetchingAnim}
                  onClick={this.animate}
                >
                  Animate
                </Button>
              </Button.Group>

              {device_type === 'eagle' || tpms_connected ? (
                <Button.Group
                  size='mini'
                  basic
                  fluid
                  color='blue'
                  style={{ marginTop: '10px', width: '360px' }}
                >
                  {/* {device_type === "eagle" && (
                    <Button>
                      <Link
                        to={{
                          pathname: "/media/images"
                        }}
                        target="_blank"
                      >
                        Gallery
                      </Link>
                    </Button>
                  )} */}
                  {tpms_connected && (
                    <Button onClick={this.handleTpmsData}>Tire</Button>
                  )}
                </Button.Group>
              ) : null}
            </div>

            <div style={style.boxStyle}>
              <div style={style.carDetailsInfo}>
                <div style={{ marginTop: '5px', marginBottom: 10 }}>
                  <h4 style={{ color: '#32325d', marginBottom: 0 }}>Speed</h4>
                  <h5
                    style={{
                      ...style.FontColor,
                      marginTop: 0,
                      fontWeight: 500,
                    }}
                  >
                    {Math.round(
                      acc_connected ? (engine_sensor ? speed : 0) : speed || 0,
                    )}
                    &nbsp;km/h
                  </h5>
                </div>

                <div style={{ marginTop: '5px', marginBottom: 10 }}>
                  <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                    Last Seen
                  </h4>
                  <h5 style={{ ...style.FontColor, marginTop: 0 }}> {time}</h5>
                </div>

                {/* <div style={{ marginTop: '5px' }}>
                  <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                    Service Status
                  </h4>
                  <h5 style={style.FontColor}> {status}</h5>
                </div> */}

                <div style={{ marginTop: '5px', marginBottom: 10 }}>
                  <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                    Bill Clear Up To
                  </h4>
                  <h5 style={{ ...style.FontColor, marginTop: 0 }}>
                    {valid_till !== 'Not Available'
                      ? valid_till.slice(0, 10)
                      : valid_till}
                  </h5>
                </div>

                {acc_connected ? (
                  <div style={{ marginTop: '5px' }}>
                    <h4 style={{ color: '#32325d', marginBottom: 3 }}>
                      Ignition Status
                    </h4>
                    <h5 style={{ ...style.FontColor, marginTop: 0 }}>
                      {engine_sensor ? (
                        <span style={{ verticalAlign: 'top' }}>
                          <span className='green-circle' />
                          On {speed > 0 ? null : `[Idle]`}
                        </span>
                      ) : (
                        <span style={{ verticalAlign: 'top' }}>
                          <span className='red-circle' />
                          Off
                        </span>
                      )}
                    </h5>
                  </div>
                ) : null}
                {engine_control && engine_locked !== null ? (
                  <div style={{ marginTop: '5px' }}>
                    <h4 style={{ color: '#32325d', marginBottom: 3 }}>
                      Engine Status
                    </h4>
                    <h5 style={{ ...style.FontColor, marginTop: 5 }}>
                      {engine_locked ? (
                        <span style={{ verticalAlign: 'top' }}>
                          <span className='red-circle' />
                          Locked
                        </span>
                      ) : (
                        <span style={{ verticalAlign: 'top' }}>
                          <span className='green-circle' />
                          Unlocked
                        </span>
                      )}
                    </h5>
                  </div>
                ) : null}

                {io_element_status ? <IOElements assetId={assetId} /> : null}

                {isTemperatureSensorEnabled ? (
                  <div style={{ marginTop: '5px' }}>
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 10 }}
                    >
                      <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                        Temperature
                      </h4>
                      <Button
                        onClick={this.handleTemperatureGauge}
                        icon
                        size='mini'
                      >
                        <Icon name='chart area' />
                      </Button>
                    </div>

                    {sensor_1.enabled ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`${
                          temperature_sensor_name || 'Temperature 1'
                        }:\u00A0\u00A0\u00A0${Number(sensor_1.value).toFixed(
                          1,
                        )} °C `}
                      </h5>
                    ) : null}
                    {sensor_2.enabled ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`${
                          temperature_sensor_2_name || 'Temperature 2'
                        }:\u00A0\u00A0\u00A0${Number(sensor_2.value).toFixed(
                          1,
                        )} °C `}
                      </h5>
                    ) : null}
                    {sensor_3.enabled ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`${
                          temperature_sensor_3_name || 'Temperature 3'
                        }:\u00A0\u00A0\u00A0${Number(sensor_3.value).toFixed(
                          1,
                        )} °C `}
                      </h5>
                    ) : null}
                    {sensor_4.enabled ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`${
                          temperature_sensor_4_name || 'Temperature 4'
                        }:\u00A0\u00A0\u00A0${Number(sensor_4.value).toFixed(
                          1,
                        )} °C `}
                      </h5>
                    ) : null}
                    {sensor_5.enabled ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`${
                          temperature_sensor_5_name || 'Temperature 5'
                        }:\u00A0\u00A0\u00A0${Number(sensor_5.value).toFixed(
                          1,
                        )} °C `}
                      </h5>
                    ) : null}
                    {sensor_6.enabled ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`${
                          temperature_sensor_6_name || 'Temperature 6'
                        }:\u00A0\u00A0\u00A0${Number(sensor_6.value).toFixed(
                          1,
                        )} °C `}
                      </h5>
                    ) : null}
                    {sensor_7.enabled ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`${
                          temperature_sensor_7_name || 'Temperature 7'
                        }:\u00A0\u00A0\u00A0${Number(sensor_7.value).toFixed(
                          1,
                        )} °C `}
                      </h5>
                    ) : null}
                    {sensor_8.enabled ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`${
                          temperature_sensor_8_name || 'Temperature 8'
                        }:\u00A0\u00A0\u00A0${Number(sensor_8.value).toFixed(
                          1,
                        )} °C `}
                      </h5>
                    ) : null}
                  </div>
                ) : null}

                {digital_input_sensor_1 ||
                digital_input_sensor_2 ||
                digital_input_sensor_3 ? (
                  <div style={{ marginTop: 20 }}>
                    <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                      Digital Indicator
                    </h4>
                    {digital_input_sensor_1 ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`${
                          digital_input_sensor_1_name || 'Digital Input 1'
                        }:\u00A0 ${digitalInputValueFormat(
                          digital_input_sensor_1_name,
                          digital_input_1,
                          digital_input_sensor_1_reversed,
                        )} `}
                      </h5>
                    ) : null}
                    {digital_input_sensor_2 ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`${
                          digital_input_sensor_2_name || 'Digital Input 2'
                        }:\u00A0 ${digitalInputValueFormat(
                          digital_input_sensor_2_name,
                          digital_input_2,
                          digital_input_sensor_2_reversed,
                        )}`}
                      </h5>
                    ) : null}
                    {digital_input_sensor_3 ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`${
                          digital_input_sensor_3_name || 'Digital Input 3'
                        }:\u00A0 ${digitalInputValueFormat(
                          digital_input_sensor_3_name,
                          digital_input_3,
                          digital_input_sensor_3_reversed,
                        )}`}
                      </h5>
                    ) : null}
                  </div>
                ) : null}

                {isHumiditySensorEnabled ? (
                  <div style={{ marginTop: 20 }}>
                    <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                      Humidity
                    </h4>
                    {humidity_sensor_1 ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`Humidity 1:\u00A0 ${humidity_1}%`}
                      </h5>
                    ) : null}
                    {humidity_sensor_2 ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`Humidity 2:\u00A0 ${humidity_2}%`}
                      </h5>
                    ) : null}
                    {humidity_sensor_3 ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`Humidity 3:\u00A0 ${humidity_3}%`}
                      </h5>
                    ) : null}
                    {humidity_sensor_4 ? (
                      <h5 style={{ ...style.FontColor, marginTop: 10 }}>
                        {`Humidity 4:\u00A0 ${humidity_4}%`}
                      </h5>
                    ) : null}
                  </div>
                ) : null}

                {fuel_connected ? (
                  <div style={{ marginTop: '5px' }}>
                    <h4 style={{ color: '#32325d', marginBottom: 3 }}>Fuel</h4>
                    <div style={style.FuelMeter}>
                      <FuelGauge fuel={fuel} tankSize={tank_size} />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {video ? (
              <div style={style.boxStyle}>
                <h4 style={{ textAlign: 'center' }}>
                  <strong>Video</strong>
                </h4>
                <Divider style={style.divider} fitted />
                {device_type === 'jc400p' ? (
                  <JC400Stream assetId={assetId} />
                ) : null}
                {device_type === 'jc120' ? (
                  <JC120Stream assetId={assetId} />
                ) : null}
                {device_type === 'jc181' ? (
                  <JC181Stream assetId={assetId} />
                ) : null}
              </div>
            ) : null}
          </div>

          {!isCrown() ? null : (
            <div style={style.landMarkBox}>
              <div style={{ marginTop: '5px' }}>
                <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                  Delivery Number
                </h4>
                <h5 style={{ ...style.FontColor, marginTop: 5 }}>
                  {delivery_number ? delivery_number : 'Not Available'}
                </h5>
              </div>
              <div style={{ marginTop: '10px' }}>
                <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                  Chalan Number
                </h4>
                <h5 style={{ ...style.FontColor, marginTop: 5 }}>
                  {chalan_number ? chalan_number : 'Not Available'}
                </h5>
              </div>
              <div style={{ marginTop: '10px' }}>
                <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                  Chalan Quantity
                </h4>
                <h5 style={{ ...style.FontColor, marginTop: 5 }}>
                  {chalan_quantity ? chalan_quantity : 'Not Available'}
                </h5>
              </div>
              <div style={{ marginTop: '10px' }}>
                <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                  Destination Address
                </h4>
                <h5 style={{ ...style.FontColor, marginTop: 5 }}>
                  {destination_address ? destination_address : 'Not Available'}
                </h5>
              </div>
              <div style={{ marginTop: '10px' }}>
                <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                  Driver Name
                </h4>
                <h5 style={{ ...style.FontColor, marginTop: 5 }}>
                  {driver_name ? driver_name : 'Not Available'}
                </h5>
              </div>
              <div style={{ marginTop: '10px' }}>
                <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                  Driver Mobile Number
                </h4>
                <h5 style={{ ...style.FontColor, marginTop: 5 }}>
                  {driver_mobile_number
                    ? driver_mobile_number
                    : 'Not Available'}
                </h5>
              </div>
              <div style={{ marginTop: '10px' }}>
                <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                  Trip Status
                </h4>
                <h5 style={{ ...style.FontColor, marginTop: 5 }}>
                  {trip_status ? trip_status : 'Not Available'}
                </h5>
              </div>
              <div style={{ marginTop: '10px' }}>
                <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                  Source Plant Name
                </h4>
                <h5 style={{ ...style.FontColor, marginTop: 5 }}>
                  {source_plant_name ? source_plant_name : 'Not Available'}
                </h5>
              </div>
              <div style={{ marginTop: '10px' }}>
                <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                  Vehicle Type
                </h4>
                <h5 style={{ ...style.FontColor, marginTop: 5 }}>
                  {vehicle_type ? vehicle_type : 'Not Available'}
                </h5>
              </div>
            </div>
          )}
          <br />
          {window.localStorage.getItem('organization') ===
            '56ab7fee31414940ecd1f58a' ||
          window.localStorage.getItem('organization') ===
            '5efd6d101d41c855060b1632' ? (
            <div style={style.landMarkBox}>
              <Direction assetPosition={position} />
            </div>
          ) : null}
          <br />
          {window.localStorage.getItem('username') ? null : (
            <div style={style.landMarkBox}>
              <Link to={`/geofence/draw?lat=${position[0]}&lng=${position[1]}`}>
                <Button fluid>Create Geofence</Button>
              </Link>
            </div>
          )}

          {JSON.parse(window.localStorage.getItem('org_admin')) &&
          engine_control &&
          engine_locked !== null ? (
            <div style={style.landMarkBox}>
              <h4 style={{ textAlign: 'center' }}>
                <strong>Engine Control</strong>
              </h4>
              <Divider style={style.divider} fitted />

              <div style={{ marginTop: '5px' }}>
                <EngineCommand assetId={assetId} engineLocked={engine_locked} />
              </div>
            </div>
          ) : null}

          <div style={style.landMarkBox}>
            <h4 style={{ textAlign: 'center' }}>
              <strong>Nearest Landmarks</strong>
            </h4>
            <Divider style={style.divider} fitted />
            {this.props.landmarks.length > 0
              ? this.props.landmarks.map((item, idx) => {
                  let eastWest;
                  let northSouth;
                  let distanceCalculate;

                  if (item.distance > 999) {
                    distanceCalculate = (item.distance / 1000).toFixed(2);
                    distanceCalculate += ' Km  ';
                  } else {
                    distanceCalculate = item.distance;
                    distanceCalculate += ' m  ';
                  }

                  if (position[1] > item.position[1]) {
                    eastWest = 'W';
                  } else {
                    eastWest = 'E';
                  }
                  if (position[0] > item.position[0]) {
                    northSouth = 'S';
                  } else {
                    northSouth = 'N';
                  }
                  return (
                    <p key={idx}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <div>
                          <i className='marker icon' />
                        </div>
                        <div>
                          <span>
                            <strong>
                              {distanceCalculate} {northSouth}
                              {eastWest}
                            </strong>{' '}
                            <span style={{ color: '#525f7f', fontSize: 15 }}>
                              from {item.name}
                            </span>
                          </span>
                        </div>
                      </div>
                    </p>
                  );
                })
              : 'Not Available'}
          </div>
          <br />

          {window.localStorage.getItem('rancon') ? (
            <div>
              <h5>Search Asset Within (km)</h5>
              <Button
                circular
                color='teal'
                onClick={this.changeDistance.bind(this, 1)}
              >
                1
              </Button>
              <Button
                circular
                color='teal'
                onClick={this.changeDistance.bind(this, 2)}
              >
                2
              </Button>
              <Button
                circular
                color='teal'
                onClick={this.changeDistance.bind(this, 3)}
              >
                3
              </Button>
              <Button
                circular
                color='teal'
                onClick={this.changeDistance.bind(this, 4)}
              >
                4
              </Button>
              <Button
                circular
                color='teal'
                onClick={this.changeDistance.bind(this, 5)}
              >
                5
              </Button>
            </div>
          ) : null}

          <Modal open={showTpmsData} onClose={this.handleTpmsData}>
            <TPMS tpmsData={tpms_data} />
          </Modal>

          <Modal
            open={showTemperatureGauge}
            onClose={this.handleTemperatureGauge}
            size='mini'
            dimmer={false}
            style={{ width: 650 }}
          >
            <div style={{ position: 'relative', paddingBottom: 10 }}>
              <Icon
                name='remove'
                color='red'
                circular
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: 0,
                  top: 7,
                  zIndex: 10,
                  background: 'white',
                }}
                onClick={this.handleTemperatureGauge}
              />
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  background: 'white',
                  padding: 4,
                }}
              >
                <p style={{ fontSize: 11 }}>
                  <span style={{ fontWeight: 'bold' }}>Updated at: </span>
                  {moment(time, 'dddd, MMMM Do, YYYY, hh:mm:ss A').format(
                    'DD-MM-YYYY, hh:mm A',
                  )}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 10,
                }}
              >
                <h4 style={{ color: '#32325d', marginBottom: 0 }}>
                  Temperature of{' '}
                  <span style={{ fontWeight: 'bold' }}>{name}</span>
                </h4>
              </div>

              <Grid columns={temperature_chart_column}>
                {sensor_1.enabled ? (
                  <Grid.Column>
                    <Segment style={{ padding: 0 }}>
                      <TemperatureGauge
                        name={
                          temperature_sensor_name
                            ? temperature_sensor_name
                            : 'Temperature 1'
                        }
                        value={sensor_1.value}
                      />
                    </Segment>
                  </Grid.Column>
                ) : null}
                {sensor_2.enabled ? (
                  <Grid.Column>
                    <Segment style={{ padding: 0 }}>
                      <TemperatureGauge
                        name={
                          temperature_sensor_2_name
                            ? temperature_sensor_2_name
                            : 'Temperature 2'
                        }
                        value={sensor_2.value}
                      />
                    </Segment>
                  </Grid.Column>
                ) : null}
                {sensor_3.enabled ? (
                  <Grid.Column>
                    <Segment style={{ padding: 0 }}>
                      <TemperatureGauge
                        name={
                          temperature_sensor_3_name
                            ? temperature_sensor_3_name
                            : 'Temperature 3'
                        }
                        value={sensor_3.value}
                      />
                    </Segment>
                  </Grid.Column>
                ) : null}
                {sensor_4.enabled ? (
                  <Grid.Column>
                    <Segment style={{ padding: 0 }}>
                      <TemperatureGauge
                        name={
                          temperature_sensor_4_name
                            ? temperature_sensor_4_name
                            : 'Temperature 4'
                        }
                        value={sensor_4.value}
                      />
                    </Segment>
                  </Grid.Column>
                ) : null}
                {sensor_5.enabled ? (
                  <Grid.Column>
                    <Segment style={{ padding: 0 }}>
                      <TemperatureGauge
                        name={
                          temperature_sensor_5_name
                            ? temperature_sensor_5_name
                            : 'Temperature 5'
                        }
                        value={sensor_5.value}
                      />
                    </Segment>
                  </Grid.Column>
                ) : null}
                {sensor_6.enabled ? (
                  <Grid.Column>
                    <Segment style={{ padding: 0 }}>
                      <TemperatureGauge
                        name={
                          temperature_sensor_6_name
                            ? temperature_sensor_6_name
                            : 'Temperature 6'
                        }
                        value={sensor_6.value}
                      />
                    </Segment>
                  </Grid.Column>
                ) : null}
                {sensor_7.enabled ? (
                  <Grid.Column>
                    <Segment style={{ padding: 0 }}>
                      <TemperatureGauge
                        name={
                          temperature_sensor_7_name
                            ? temperature_sensor_7_name
                            : 'Temperature 7'
                        }
                        value={sensor_7.value}
                      />
                    </Segment>
                  </Grid.Column>
                ) : null}
                {sensor_8.enabled ? (
                  <Grid.Column>
                    <Segment style={{ padding: 0 }}>
                      <TemperatureGauge
                        name={
                          temperature_sensor_8_name
                            ? temperature_sensor_8_name
                            : 'Temperature 8'
                        }
                        value={sensor_8.value}
                      />
                    </Segment>
                  </Grid.Column>
                ) : null}
              </Grid>
            </div>
          </Modal>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

const style = {
  buttonGroup: {
    backgroundColor: 'white',
    color: '#988e8e',
    width: '365px',
    height: '40px',
    float: 'left',
    padding: '5px',
    borderRadius: '5px',
    marginBottom: '15px',
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12)',
  },
  landMarkBox: {
    backgroundColor: 'white',
    color: '#24b47e',
    width: '365px',
    float: 'left',
    padding: '15px',
    borderRadius: '5px',
    marginBottom: '15px',
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12)',
  },
  boxStyle: {
    backgroundColor: 'white',
    color: '#988e8e',
    fontWeight: '600',
    width: '365px',
    float: 'left',
    padding: '15px',
    borderRadius: '5px',
    marginBottom: '15px',
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12)',
  },
  divider: {
    marginBottom: '15px',
  },
  assetInfoDetail: {},
  carDetailsInfo: {
    marginTop: '12px',
    color: '#988e8e',
  },

  FontColor: {
    color: '#988e8e',
  },
};

export default Details;
