import assets from './assets';
import timer from './timer';
import user from './user';

const stores = {
  assets,
  timer,
  user,
};

export default stores;
