import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Message, Divider } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import { postData, sendCode } from './../../helper/dataService.js';
import { REGULAR_PATH, AUTH_CONFIG } from '../../../env';
import LoginGoogle from './LoginGoogle';

const STAFF_EMAIL_DOMAIN = ['finder-lbs.com', 'amantech.com.np'];

@inject('user')
@observer
class Login extends React.Component {
  static defaultProps = {
    postInfo: {
      client_id: AUTH_CONFIG.id,
      client_secret: AUTH_CONFIG.secret,
      grant_type: 'client_credentials',
      scope: '',
      timezone: new Date().getTimezoneOffset(),
    },
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      input: '',
      password: '',
      accessToken: '',
      refreshToken: '',
      registered: '',
      color: 'ui message info',
      loading: false,
    };
  }

  checkVerification = (token, refresh, data, loginType) => {
    if (loginType === 'email') {
      let verifyResponse = sendCode(
        `${REGULAR_PATH}/auth/send_email?access_token=${token}`,
      );
      verifyResponse.then((res) => {
        this.props.history.push({
          pathname: '/user-verify',
          state: {
            verifyType: res.data.status,
            accessToken: token,
            refreshToken: refresh,
            data: data,
          },
        });
      });
      return;
    } else {
      let verifyResponse = sendCode(
        `${REGULAR_PATH}/auth/send_code?access_token=${token}`,
      );
      verifyResponse.then((res) => {
        this.props.history.push({
          pathname: '/user-verify',
          state: {
            verifyType: res.data.status,
            accessToken: token,
            refreshToken: refresh,
            data: data,
          },
        });
      });
      return;
    }
  };

  loginUser = (postInfo) => {
    let loginResponse = postData(
      `${REGULAR_PATH}/auth/login`,
      JSON.stringify(postInfo),
    );
    loginResponse
      .then(
        ({
          data: {
            status,
            access_token,
            refresh_token,
            user = {},
            staff,
            allow_access,
          },
        }) => {
          const {
            email,
            _id,
            mobile,
            organization,
            mobile_verified,
            email_verified,
            org_admin,
            username,
          } = user;

          this.setState({ loading: false });

          const { state } = this.props.location;
          const redirectTo = state && state.from ? state.from : '/';

          if (
            status === 'password is not correct' ||
            status === 'user not found'
          ) {
            this.setState({
              registered: 'Incorrect Email/Mobile/Password',
            });
          } else if (status === 'user registered with socialauth') {
            this.setState({
              registered: 'Please sign in with Google',
            });
          } else if (username) {
            window.localStorage.setItem('username', username);
            window.localStorage.setItem('user', access_token);
            window.localStorage.setItem('refresh', refresh_token);
            window.localStorage.setItem('user_id', _id);
            window.localStorage.setItem('org_admin', false);
            window.localStorage.setItem('email', '');
            window.localStorage.setItem('organization', organization);
            window.localStorage.setItem('real_org', organization);
            window.localStorage.setItem('mobile', '');
            window.localStorage.setItem('mobile_verified', true);

            this.props.history.replace(redirectTo);
          } else if (user) {
            if (this.state.input.includes('@') && !email_verified) {
              return this.checkVerification(
                access_token,
                refresh_token,
                user,
                'email',
              );
            }

            if (!this.state.input.includes('@') && !mobile_verified) {
              return this.checkVerification(
                access_token,
                refresh_token,
                user,
                'mobile',
              );
            }

            window.localStorage.setItem('user', access_token);
            window.localStorage.setItem('refresh', refresh_token);
            window.localStorage.setItem('user_id', _id);
            window.localStorage.setItem('org_admin', org_admin);
            window.localStorage.setItem('email', email);
            window.localStorage.setItem('organization', organization);
            window.localStorage.setItem('real_org', organization);
            if (/(01)(3|5|6|7|8|9)\d{8}$/.test(mobile)) {
              window.localStorage.setItem('mobile', mobile);
            }

            window.localStorage.setItem('mobile_verified', mobile_verified);
            const emailDomain = email.split('@')[1];
            if (STAFF_EMAIL_DOMAIN.includes(emailDomain)) {
              if (staff) {
                window.localStorage.setItem('staff', JSON.parse(staff));
              }
              if (allow_access) {
                window.localStorage.setItem(
                  'allowAccess',
                  JSON.parse(allow_access),
                );
              }
            }

            this.props.history.push(redirectTo);
          }
        },
      )
      .catch((e) => e);
  };

  onChangeInput = (e) => {
    this.setState({
      input: e.target.value,
    });
  };
  onChangePassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      registered: '',
    });
    this.props.postInfo['user'] = this.state.input;
    this.props.postInfo['password'] = this.state.password;
    this.props.postInfo['login_type'] = 'normal';
    this.loginUser(this.props.postInfo);
  };

  render() {
    return (
      <div className='ui two column centered grid'>
        <div className='column'>
          <div>
            <Link to='/'>
              <img
                className='logo'
                src='/images/Logo-1.png'
                width='300'
                alt='Finder logo'
              />
            </Link>
            <br />
            <br />
            <div
              className='ui stacked segment'
              style={{ paddingBottom: '2em', borderRadius: '10px' }}
            >
              <br />
              {this.state.registered && (
                <Message header={this.state.registered} warning />
              )}
              <form onSubmit={this.submit} className='ui large form'>
                <div className='field'>
                  <div className='ui left icon input'>
                    <i className='user icon' />
                    <input
                      required
                      onChange={this.onChangeInput}
                      value={this.state.input}
                      type='text'
                      pattern='[^@]+@[^@]+\.[a-zA-Z]{2,6}|+9779[6-8]\d{8}|^+8801[3-9]\d{8}$'
                      name='text'
                      placeholder='Email/Mobile'
                    />
                  </div>
                </div>

                <div className='field'>
                  <div className='ui left icon input'>
                    <i className='lock icon' />
                    <input
                      type='password'
                      required
                      onChange={this.onChangePassword}
                      value={this.state.password}
                      name='email'
                      placeholder='Password'
                    />
                  </div>
                </div>
                <br />

                <div className='field'>
                  <Button
                    color='teal'
                    fluid
                    loading={this.state.loading}
                    style={{ fontSize: '16px' }}
                  >
                    LOGIN
                  </Button>
                </div>
              </form>
              <br />
              <div className='field'>
                <Link to='/forget' style={{ cursor: 'pointer' }}>
                  Forgot password?
                </Link>
              </div>
              <Divider horizontal>Or</Divider>
              <div className='ui grid'>
                <div
                  className='sixteen wide column'
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <LoginGoogle buttonText='Sign in with Google' />
                </div>
              </div>
              <br />
              <div style={{ fontSize: '18px', color: 'black' }}>
                <b>New to us?</b>
                <span>
                  <Link
                    to='/signUp'
                    style={{
                      color: '#07c3c3',
                      marginLeft: '5px',
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                    }}
                  >
                    Sign Up Now
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
